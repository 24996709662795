<template>
  <div class="week-box">
    <div class="week-year">
      <span>Year：</span>
      <div v-for="(item, index) in year" :key="index" class="table-item hand" :class="{ active: current === index }" @click="handleTabItem(index)">{{item}}</div>
    </div>
    <div class="week-body">
      <media-card v-for="(item,index) in list" :key="index" :item="item" @handleMedia="handleMedia(index)"/>
    </div>
    <div class="week-footer">
      <pagination :total="total" :page.sync="ListQuery.PageNum" :size.sync="ListQuery.PageSize" @pagination="getList"/>
    </div>
  </div>
</template>

<script>
import MediaCard from './media-card'
import Pagination from '@/components/pagination'
import { getLocalStore } from '@/utils/common'

export default {
  name: 'MarketAnalysis',
  components: {
    MediaCard,
    Pagination
  },
  data () {
    return {
      current: 0,
      total: 0,
      year: ['2021', '2020', '2019'],
      list: [],
      allList: [],
      ListQuery: {
        PageNum: 1,
        PageSize: 15,
        ReportType: '_marketanalysis',
        LanguageCode: 'en',
        Year: '2021',
        UserID: getLocalStore().UserID
      }
    }
  },
  mounted () {
    this.getList(true)
  },
  methods: {
    getList (isAll = false) {
      this.$store.dispatch('GetWebFrontMarketReport', this.ListQuery).then(() => {
        this.total = this.$store.getters.priceTotal
        this.list = this.$store.getters.priceRows
        if (isAll) {
          this.getAllList()
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    getAllList () {
      const allQuery = Object.assign({}, this.ListQuery)
      allQuery.PageSize = this.total

      this.$store.dispatch('GetWebFrontMarketReport', allQuery).then(() => {
        this.allList = this.$store.getters.priceRows
      }).catch((err) => {
        console.log(err)
      })
    },
    handleTabItem (index) {
      this.current = index
      this.ListQuery.Year = this.year[index]
      this.ListQuery.PageNum = 1
      this.getList(true)
    },
    handleMedia (index) {
      const sliceList = this.allList.slice(0).splice(index + (this.ListQuery.PageSize * (this.ListQuery.PageNum - 1)), 3)
      this.$emit('handleNoMore', sliceList)
    }
  }
}
</script>

<style lang="scss" scoped>

.week-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.week-year {
  padding: 20px 0 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  font-weight: bold;
}

.week-year > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 60px;
  margin: 0 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 1px #e1e1e1;
}

.week-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}
.week-footer{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.active {
  color: #ffffff;
  background-color: #014479!important;
}

</style>
