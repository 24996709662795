<template>
  <div class="item-box">
    <div style="height: 20px"/>
    <div v-for="(item, index) in items" :key="index" @click="handleToggle(item, index)">
      <div :class="{ active: isCurrent(index) }" class="item hand">
        <img v-if="isCurrent(index)" :src="item.icon[1]">
        <img v-if="!isCurrent(index)" :src="item.icon[0]">
        <span :style="{color:(item.value === 'MarketAnalysis' ? '#014479' : '#333333')}">{{ item.label }}</span>
        <div :class="{ 'arrow-down': isCurrent(index), 'ac-2': isCurrent(index), hide: item.children.length === 0 }" class="btc-arrow arrow-up ac-1" ></div>
      </div>
      <div>
        <div v-show="isCurrent(index) && item.children.length > 0" v-for="(child, cindex) in item.children" :key="child.value" class="item-child" @click.stop="handleClickItem(child, cindex)">
          <dotline :is-last="cindex === item.children.length - 1" :is-active="currentChild === cindex" :childLabel="child.label"/>
          <span class="hand" :class="{ cactive: currentChild === cindex }">{{ child.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dotline from './components/dotline'

export default {
  name: 'BtcDataSidebar',
  components: {
    Dotline
  },
  data () {
    return {
      current: 0,
      currentChild: 0,
      items: [
        {
          label: 'Market Price',
          value: 'MarketPrice',
          icon: [
            require('@/assets/image/price/price2.png'),
            require('@/assets/image/price/price21.png')
          ],
          children: [
            { label: 'Spot', value: '_marketSpot' },
            { label: 'Futures', value: '_marketFutures' }
          ]
        },
        {
          label: 'Price Trends',
          value: 'PriceIndex',
          icon: [
            require('@/assets/image/price/price1.png'),
            require('@/assets/image/price/price11.png')
          ],
          children: [
            { label: 'Beef', value: '_beef' },
            { label: 'Live Cattle', value: '_liveCattle' }
          ]
        },
        {
          label: 'Ups and Downs',
          value: 'UpsAndDowns',
          icon: [
            require('@/assets/image/price/price3.png'),
            require('@/assets/image/price/price31.png')
          ],
          children: [
            { label: 'Spot', value: '_upDownSpot' },
            { label: 'Futures', value: '_upDownFutures' }
          ]
        },
        {
          label: 'Market Analysis',
          value: 'MarketAnalysis',
          icon: [
            require('@/assets/image/cn/fire.png'),
            require('@/assets/image/cn/fire.png')
          ],
          children: []
        }
      ]
    }
  },
  methods: {
    isCurrent (index) {
      return this.current === index
    },
    handleToggle (item, index) {
      this.currentChild = -1
      this.current = this.current !== index ? index : -1
      this.$emit('tab-outer', item)
    },
    handleClickItem (item, index) {
      this.currentChild = index
      this.$emit('tab-inner', item)
    }
  }
}
</script>

<style lang="scss" scoped>
$dot-line-color: #e8e8e7;

.item-box{
  color: #666666;
}

.item {
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 10px;
  font-size: 14px;
}

.item > img {
  width: 19px;
  height: 16px;
}

.item > span {
  width: 160px;
}

.item-child {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-left: 15px;
  font-size: 14px;
}

.item-child:first-child {
  margin-top: 8px;
}

.item-child > span {
  margin-left: 20px;
}

.active {
  color: #024579;
  background-color: #e4f2ff;
}

.cactive{
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}
</style>
