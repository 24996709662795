<template>
  <div class="search-picker-box" @click.stop>
    <div class="search-picker-bar">
      <div class="hand">
        <span>{{ name }}</span>
        <div :class="{ 'triangle-up': isShow, 'triangle-down': !isShow }" class="btc-triangle"></div>
      </div>
      <div>
        <input v-model="input" type="text" @input="handleInput" @click.stop>
        <img src="@/assets/image/data/search.png" style="width: 12px; height: 12px;">
      </div>
    </div>
    <div v-if="mode === 'one'" v-show="isShow" class="search-picker-drop-down one-col">
      <div v-for="(item, index) in items" :key="index" :class="{ active: currentVisible === index }" class="hand" @click="handleTabItem(index)">{{ item }}</div>
    </div>
    <div v-if="mode === 'two'" v-show="isShow" class="search-picker-drop-down two-col">
      <div v-for="(item, index) in items" :key="index" :class="{ left: index % 2 === 0, active: currentVisible === index }" class="hand" @click="handleTabItem(index)">{{ item }}</div>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'SearchPicker',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: 'Country'
    },
    mode: {
      type: String,
      default: 'one'
    },
    current: {
      type: Number,
      default: -1
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items: {
      get () {
        return this.temp || this.data
      },
      set (val) {
        this.temp = val
      }
    },
    currentVisible: {
      get () {
        return this.current
      },
      set (val) {
        this.$emit('update:current', val)
      }
    },
    dataVisible: {
      get () {
        return this.data
      },
      set (val) {
        this.$emit('update:data', val)
      }
    }
  },
  watch: {
    data (oval, nval) {
      if (oval !== nval) {
        this.input = ''
        this.handleInput()
      }
    }
  },
  data () {
    return {
      input: '',
      temp: null
    }
  },
  methods: {
    handleInput () {
      this.items = this.data.filter(v => {
        return v.toLowerCase().includes(this.input.toLowerCase())
      })
    },
    handleTabItem (index) {
      if (this.$store.getters.token) {
        this.currentVisible = index
        this.input = ''
        this.$emit('handleTabItem', this.items[index])
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-picker-box {
  font-size: 12px;
  position: relative;
  margin-right: 20px;
}
.search-picker-bar {
  display: flex;
}
.search-picker-bar > div {
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border: 1px solid #5f6162;
  font-weight: bold;
}
.search-picker-bar > div > input {
  font-weight: bold;
  width: 50px;
  height: 25px;
  border: none;
  outline: none;
}
.search-picker-bar > div:first-child {
  border-radius: 15px 0 0 15px;
}
.search-picker-bar > div:last-child {
  border-left: none;
  border-radius: 0 15px 15px 0;
}

.search-picker-drop-down {
  background: #ffffff;
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 10px;
  box-shadow: 0 0 5px #e0e0e0;
  overflow-y: scroll;
}
.one-col {
  height: 180px;
  width: 170px;
  right: 20%;
  padding: 8px 0;
}
.one-col > div {
  padding: 4px 10px;
}
.active {
  color: #ffffff;
  background: #014479;
}
.two-col {
  height: 200px;
  right: -40%;
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-flow: wrap;
}
.two-col > div {
  width: 50%;
  height: 30px;
  padding: 0 10px;
  text-align: center;
  line-height: 30px;
  border-bottom: 1px solid #e0e0e0;
}
.left {
  border-right: 1px solid #e0e0e0;
}
</style>
