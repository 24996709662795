import { render, staticRenderFns } from "./market-price-spot.vue?vue&type=template&id=0b57651d&scoped=true&"
import script from "./market-price-spot.vue?vue&type=script&lang=js&"
export * from "./market-price-spot.vue?vue&type=script&lang=js&"
import style0 from "./market-price-spot.vue?vue&type=style&index=0&lang=css&"
import style1 from "./market-price-spot.vue?vue&type=style&index=1&id=0b57651d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b57651d",
  null
  
)

export default component.exports